import React from "react";
import * as styles from "./ThankYouComponent.module.scss";
import { cn } from "../../utils";
import TitleUnderlineParagraph from "../../components/TitleUnderlineParagraph/TitleUnderlineParagraph";
import ThankYouComponentI from "./ThankYouComponent.type";
import Heading from "../Typography/Heading/Heading";
import Button from "../Button/Button";

const ThankYouComponent = ({ type }: ThankYouComponentI) => {
    return (
        <div className={styles.section}>
            <div className={cn(styles.container, "container")}>
                {type === "thanks" && (
                    <TitleUnderlineParagraph
                        heading="Thank you for your interest in Christina."
                        headingLevel="h1"
                        text={`Please check your email for more information about our exclusive real estate offerings.`}
                        textSize={"large"}
                        underline={"center"}
                        align="center"
                    />
                )}

                {type === "thankYou" && (
                    <>
                        <Heading level={"h1"} className={styles.heading}>
                            Thank you for your interest in Christina
                        </Heading>
                        <p className={styles.subtitle}>
                            Please check your email for more information about
                            our exclusive real estate offerings.
                        </p>
                        <div className={styles.underline} />
                        <Heading level={"h3"} className={styles.subheading}>
                            Looking for a more personal touch?
                        </Heading>

                        <p className={styles.subtitleSecond}>
                            One of our experts can walk you through our
                            exclusive offerings over the phone.
                        </p>

                        <div className={styles.buttonWrapper}>
                            <Button
                                type={"small"}
                                color={"white"}
                                href="https://calendly.com/christina-real-estate/15min"
                                target={"_blank"}
                                className={styles.button}
                            >
                                Schedule a Call
                            </Button>
                        </div>
                    </>
                )}

                <div className={cn(styles.bg, styles.leftBg)} />
                <div className={cn(styles.bg, styles.rightBg)} />
            </div>
        </div>
    );
};

export default ThankYouComponent;
