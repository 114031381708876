// extracted by mini-css-extract-plugin
export var bg = "ThankYouComponent-module--bg--fc8cc";
export var button = "ThankYouComponent-module--button--a4ef4";
export var buttonWrapper = "ThankYouComponent-module--buttonWrapper--4c1f3";
export var container = "ThankYouComponent-module--container--c9c08";
export var element = "ThankYouComponent-module--element--e7cbc";
export var heading = "ThankYouComponent-module--heading--bc6ac";
export var leftBg = "ThankYouComponent-module--left-bg--aa741";
export var rightBg = "ThankYouComponent-module--right-bg--144cd";
export var section = "ThankYouComponent-module--section--de77f";
export var subheading = "ThankYouComponent-module--subheading--6a170";
export var subtitle = "ThankYouComponent-module--subtitle--93e74";
export var subtitleSecond = "ThankYouComponent-module--subtitleSecond--891e6";
export var underline = "ThankYouComponent-module--underline--b3091";